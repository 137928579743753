<template>
  <div>
    <!--begin: Flow type-->
    <div id="flow_type" class="form-group mt-2" v-show="isMobileDevice ? computedThirdPartiesTypes.length === 0 : true">
      <h5 id="anchorFlowType" class="group-title text-center mb-10" :class="syncedOperation.OperationType != undefined ? 'text-primary' : ''">
        <span v-if="syncedOperation.OperationType != undefined" class="label label-rounded label-md font-weight-bold align-top label-primary"
          ><i class="flaticon2-check-mark icon-sm text-white"></i
        ></span>
        <span v-else class="label label-rounded label-md font-weight-bold align-top label-opalean-gray-light">1</span>
        &nbsp;<translate>What is the flow ?</translate>
      </h5>

      <!-- Flow : radios -->
      <div class="row" v-if="!showBigIcons">
        <div v-for="(flowType, index) in computedFlowTypes" :key="index" :class="`col-md-${12 / computedFlowTypes.length}`">
          <label
            class="option option-lg border-opalean-gray-light border-dashed"
            :class="[
              syncedOperation.OperationType == flowType.id ? 'bg-primary border-0' : 'border-2',
              isDisabled(flowType.id, 'FlowType') ? 'option-disabled' : '',
            ]"
          >
            <span class="option-control mr-4">
              <span class="radio radio-lg radio-primary">
                <input
                  type="radio"
                  :value="flowType.id"
                  v-model="syncedOperation.OperationType"
                  :disabled="isDisabled(flowType.id, 'FlowType')"
                  @input="
                    (selectedOption, id) => {
                      removeAllPartners(),
                        setDefaultThirdPartyType(flowType.id),
                        jumpToAnchor('anchorThirdPartyType'),
                        $emit('update', { OperationType: flowType.id, OperationTypeSlug: flowType.slug });
                    }
                  "
                />
                <span></span>
              </span>
            </span>
            <span class="option-label" :class="syncedOperation.OperationType == index ? 'text-white' : ''">
              <span class="option-head">
                <span class="svg-icon svg-icon-2x mr-3" :class="syncedOperation.OperationType == flowType.id ? 'svg-icon-white' : `svg-icon-primary`">
                  <!--begin::Svg Icon -->
                  <inline-svg :src="flowType.icon"></inline-svg>
                  <!--end::Svg Icon-->
                </span>

                <span class="option-title w-100" :class="syncedOperation.OperationType == flowType.id ? 'text-white' : 'text-dark-75'">{{
                  $gettext(flowType.name)
                }}</span>
                <!-- <span
                class="option-focus symbol symbol-40 mr-3"
                :class="`symbol-light-primary`"
              >
                <span
                  class="symbol-label font-size-h5 font-weight-bolder"
                  >{{ $mainFunctions.getFirstLetters(flowType.name) }}</span
                >
              </span> -->

                <span class="option-focus"><i class="flaticon-questions-circular-button" v-if="showHelpDescriptions"></i></span>
              </span>
              <span
                class="option-body d-block pt-4"
                :class="syncedOperation.OperationType == flowType.id ? 'text-white' : 'text-dark-50'"
                v-if="showHelpDescriptions"
                >{{ $gettext(flowType.description) }}</span
              >
            </span>
          </label>
        </div>
      </div>

      <!-- Flow : icons -->
      <div class="d-flex justify-content-center my-5" v-if="showBigIcons">
        <div
          class="flow-item d-flex justify-content-center"
          v-for="(flowType, index) in computedFlowTypes"
          :key="index"
          :class="[`col-md-${12 / computedFlowTypes.length}`, `w-${Math.round(100 / computedFlowTypes.length)}`, index > 0 ? '--border-left' : '']"
        >
          <div
            class="pt-20 pb-10 px-5 text-center d-flex flex-column justify-content-between rounded rounded-lg w-100"
            :class="syncedOperation.OperationType == flowType.id ? 'bg-primary' : ''"
          >
            <!--begin::Icon-->
            <div class="d-flex flex-center position-relative mb-15">
              <span class="svg svg-fill-opalean-gray-medium opacity-4 position-absolute">
                <svg width="160" height="184">
                  <polyline points="80,0 160,46 160,138 80,184 0,138 0,46 80,0"></polyline>
                </svg>
              </span>
              <span class="svg-icon svg-icon-7x" :class="syncedOperation.OperationType == flowType.id ? 'svg-icon-white' : 'svg-icon-primary'">
                <!--begin::Svg Icon -->
                <inline-svg :src="flowType.icon"></inline-svg>
                <!--end::Svg Icon-->
              </span>
            </div>
            <!--end::Icon-->

            <!--begin::Content-->
            <div>
              <span
                class="font-size-h3 d-block font-weight-boldest py-2"
                :class="syncedOperation.OperationType == flowType.id ? 'text-white' : 'text-dark-75'"
                >{{ $gettext(flowType.name) }}</span
              >
              <h4
                class="font-size-lg d-block font-weight-bold mb-5"
                :class="syncedOperation.OperationType == flowType.id ? 'text-white' : 'text-dark-50'"
                v-if="showHelpDescriptions"
              >
                {{ $gettext(flowType.description) }}
              </h4>
            </div>
            <!--end::Content-->

            <!--begin::Action / @click="$emit('update', { flowType: flowType.id })"-->
            <a
              class="btn btn-pill btn-primary text-uppercase font-weight-bolder px-6 py-3 mx-auto mt-5"
              :class="syncedOperation.OperationType == flowType.id ? 'active' : ''"
              v-translate
              :disabled="isDisabled(flowType.id, 'FlowType')"
              @click="setThirdPartyType(flowType.id, $event)"
              >Choose</a
            >
            <!--end::Action-->
          </div>
        </div>
      </div>
    </div>
    <!--end: Flow type-->

    <!--begin: Separator -->
    <div class="separator separator-dashed my-10" v-show="!isMobileDevice"></div>
    <!--end: Separator -->

    <!--begin: Thirdparty flow -->
    <div id="flow_thirdparty" class="form-group" v-show="!isMobileDevice">
      <h5 id="anchorThirdPartyType" class="group-title text-center mb-10" :class="syncedOperation.MyRole != undefined ? 'text-primary' : ''">
        <span v-if="syncedOperation.MyRole != undefined" class="label label-rounded label-md font-weight-bold align-top label-primary"
          ><i class="flaticon2-check-mark icon-sm text-white"></i
        ></span>
        <span v-else class="label label-rounded label-md font-weight-bold align-top label-opalean-gray-light">2</span>
        &nbsp;<translate>Who am i inside the flow ?</translate>
      </h5>

      <div class="row">
        <div v-for="(thirdPartyType, index) in computedThirdPartyTypes" :key="index" :class="`col-md-${12 / Math.min(computedThirdPartyTypes.length, 3)}`">
          <label
            class="option option-md border-opalean-gray-light border-dashed"
            :class="[
              syncedOperation.MyRole == thirdPartyType.id ? 'bg-primary border-0' : 'border-2',
              isDisabled(thirdPartyType.id, 'thirdPartyType') ? 'option-disabled' : '',
            ]"
          >
            <span class="option-control mr-4">
              <span class="radio radio-md radio-primary">
                <input
                  type="radio"
                  :value="thirdPartyType.id"
                  v-model="syncedOperation.MyRole"
                  :disabled="isDisabled(thirdPartyType.id, 'thirdPartyType')"
                  @input="
                    (selectedOption, id) => {
                      setMyRolePartner(thirdPartyType.id),
                        $emit('update', {
                          MyRole: thirdPartyType.id,
                          MyRoleSlug: thirdPartyType.slug,
                        });
                    }
                  "
                />
                <span></span>
              </span>
            </span>
            <span class="option-label" :class="syncedOperation.MyRole == thirdPartyType.id ? 'text-white' : ''">
              <span class="option-head">
                <span class="svg-icon svg-icon-lg mr-3" :class="syncedOperation.MyRole == thirdPartyType.id ? 'svg-icon-white' : `svg-icon-primary`">
                  <!--begin::Svg Icon -->
                  <inline-svg :src="thirdPartyType.icon"></inline-svg>
                  <!--end::Svg Icon-->
                </span>

                <span class="option-title w-100" :class="syncedOperation.MyRole == thirdPartyType.id ? 'text-white' : 'text-dark-75'">{{
                  $gettext(thirdPartyType.whoName)
                }}</span>
                <!-- <span
                class="option-focus symbol symbol-40 mr-3"
                :class="`symbol-light-primary`"
              >
                <span
                  class="symbol-label font-size-h5 font-weight-bolder"
                  >{{ $mainFunctions.getFirstLetters(thirdPartyType.slug) }}</span
                >
              </span> -->
              </span>
              <span
                class="option-body d-block pt-4"
                :class="syncedOperation.MyRole == thirdPartyType.id ? 'text-white' : 'text-dark-50'"
                v-if="showHelpDescriptions"
                >{{ $gettext(thirdPartyType.description) }}</span
              >
            </span>
          </label>
        </div>
      </div>
    </div>
    <!--end: Thirdparty flow -->

    <!--begin: Separator -->
    <div class="separator separator-dashed my-10" v-show="!isMobileDevice"></div>
    <!--end: Separator -->

    <!--begin: Thirdparties flow -->
    <div id="flow_thirdparties" class="form-group" v-show="isMobileDevice ? computedThirdPartiesTypes.length !== 0 : true">
      <h5 id="anchorThirdParties" class="group-title text-center mb-10" :class="haveAnyThirdParty() ? 'text-primary' : ''">
        <span v-if="haveAnyThirdParty()" class="label label-rounded label-md font-weight-bold align-top label-primary"
          ><i class="flaticon2-check-mark icon-sm text-white"></i
        ></span>
        <span v-else class="label label-rounded label-md font-weight-bold align-top label-opalean-gray-light">3</span>
        &nbsp;<translate>Who are the thirdparties ?</translate>
      </h5>

      <div class="row --d-flex --justify-content-start --align-items-start my-5 g-4">
        <!--begin: thirdPartyTypes-->
        <div
          class="flow-item d-flex justify-content-center my-2 py-0"
          v-for="(key, index) in computedThirdPartiesTypes"
          :key="index"
          :class="['col-md-3', `--w-${100 / computedThirdPartiesTypes.length}`, index > 0 && index !== 4 ? 'border-left' : '']"
        >
          <div
            class="px-5 text-center d-flex flex-column justify-content-between w-100"
            :class="!showBigIcons ? 'py-5' : 'pt-25 pb-10'"
            v-if="!isDisabled(key.id, 'thirdPartySelect')"
          >
            <!-- v-if="!isDisabled(key.id, 'thirdPartyType')" -->
            <!--begin::Icon-->
            <div class="d-flex flex-center position-relative mb-25" v-if="showBigIcons">
              <span class="svg svg-fill-opalean-gray-medium opacity-4 position-absolute">
                <svg width="175" height="200">
                  <polyline points="87,0 174,50 174,150 87,200 0,150 0,50 87,0"></polyline>
                </svg>
              </span>

              <span class="svg-icon svg-icon-8x" :class="key.id === 'NN' ? 'svg-icon-opalean-info' : 'svg-icon-primary'">
                <!--begin::Svg Icon -->
                <inline-svg :src="key.icon"></inline-svg>
                <!--end::Svg Icon-->
              </span>
            </div>
            <!--end::Icon-->

            <!--begin::Content-->
            <div :class="isMobileDevice ? 'd-flex align-items-center' : ''">
              <span
                class="label label-lg label-inline label-rounded px-1"
                :class="{
                  'label-primary': syncedOperation.MyRole === key.id,
                  'label-warning': getAllowedThirdPartyType(syncedOperation.OperationType, syncedOperation.MyRole, 'thirdparty')[0] === key.id,
                }"
              >
                <span
                  class="svg-icon svg-icon-lg px-1"
                  :class="[
                    syncedOperation.MyRole === key.id ||
                    getAllowedThirdPartyType(syncedOperation.OperationType, syncedOperation.MyRole, 'thirdparty')[0] === key.id
                      ? 'svg-icon-light'
                      : 'svg-icon-primary',
                    key.id === 'NN' ? 'svg-icon-opalean-info' : '',
                  ]"
                >
                  <!--begin::Svg Icon -->
                  <inline-svg :src="key.icon"></inline-svg>
                  <!--end::Svg Icon-->
                </span>
              </span>
              <span
                class="font-size-h3 --d-block font-weight-boldest py-2"
                :class="{
                  'text-primary': syncedOperation.MyRole === key.id,
                  'text-warning': getAllowedThirdPartyType(syncedOperation.OperationType, syncedOperation.MyRole, 'thirdparty')[0] === key.id,
                  'text-opalean-info': 'NN' === key.id,
                  'd-block': !isMobileDevice,
                  'd-inline ml-2': isMobileDevice,
                }"
                >{{ $gettext(key.name) }}</span
              >
              <h4 class="font-size-lg d-block font-weight-bold mb-7 text-dark-50" v-if="showHelpDescriptions">
                {{ $gettext(key.description) }}
              </h4>
            </div>
            <!--end::Content-->

            <!--begin::Select Thirdparty -->
            <code v-if="showDataDebug">{{ syncedOperation.Partners.length }} {{ syncedOperation.Partners.filter((p) => p.PartnerRole === key.id) }}</code>
            <Multiselect
              :value="syncedOperation.Partners.filter((p) => p.PartnerRole === key.id)"
              :options="searchedPartners != null ? searchedPartners : filteredPartners(key.slug)"
              label="Name"
              track-by="PartnerID"
              :placeholder="$gettext('Select a thirdparty')"
              @select="
                (selectedOption, id) => {
                  emitPartner(selectedOption, id, key.id);
                  //updatePartners();
                }
              "
              @remove="(removedOption, id) => removePartner(removedOption, id, key.id)"
              :disabled="isDisabledMultiselect(key.id)"
              :class="[
                { 'border border-3 border-opalean-gray-light rounded-multiselect': !isDisabledMultiselect(key.id) },
                { 'border border-3 border-white not-allowed': isDisabledMultiselect(key.id) },
                'w-100',
              ]"
              :show-labels="false"
              :ref="`multiselect-partners-${key.id}`"
              :internal-search="false"
              @search-change="
                (searchQuery, id) => {
                  ignoreAccents(searchQuery, filteredPartners(key.slug));
                }
              "
            >
              <template slot="singleLabel" slot-scope="props">
                <span class="">{{ props.option.Name }}</span> <span class="opacity-50">{{ props.option.City }}</span>
              </template>
              <!-- :disabled="isDisabled(key.id, 'thirdPartySelect')" -->
              <template slot="option" slot-scope="props">
                <span
                  v-for="(role, index) in getMainRoleTypes(props.option.MainRole)"
                  :key="index"
                  class="symbol symbol-20 mr-3"
                  :class="[`symbol-light-${role.class}`, props.option.Attributes.includes('isActive') ? null : 'symbol-muted']"
                >
                  <span class="symbol-label font-size-xs font-weight-bolder">{{ $mainFunctions.getFirstLetters(props.option.Name) }}</span>
                </span>
                <i v-if="props.option.Attributes.includes('isClient')" class="text-opalean-gray-medium icon-md flaticon2-correct mr-1"></i>
                <span class="option__title">{{ props.option.Name }}</span> <span class="opacity-50">{{ props.option.City }}</span>
              </template>
            </Multiselect>
            <!-- @select="
                (selectedOption, id) => {
                  $emit('update', {
                    Partners: {
                      ...{
                        [thirdPartyTypes[index].id]: [
                          ...[selectedOption],
                          ...[{ PartnerRole: thirdPartyTypes[index].id, ...[selectedOption] }, ...[selectedOption]]
                        ],
                        ...{ PartnerRole: thirdPartyTypes[index].id }
                      },
                      ...{ PartnerRole: thirdPartyTypes[index].id }
                    },
                    testPartners: {
                      [thirdPartyTypes[index].id]: { ...[{ PartnerRole: thirdPartyTypes[index].id }], ...[selectedOption] }
                    },
                    [thirdPartyTypes[index].slug]: selectedOption
                  });
                }
              " -->
            <!--end::Select TP-->
          </div>
        </div>
        <div class="flow-item d-flex justify-content-center my-2 py-0 col-12" v-if="computedThirdPartiesTypes.length === 0">
          <div class="card card-custom card-fit card-border button border-2 border-dashed card-disabled">
            <!-- Body -->
            <div class="card-body px-5 py-5 opacity-50">
              <span class="d-flex align-items-center"
                ><span class="svg-icon svg-icon-3x mr-4">
                  <inline-svg src="media/opalean/svg/Flow.svg"></inline-svg>
                </span>
                <span class="font-weight-bold mr-2 opacity-50"><translate>Please choose a flow type</translate></span>
              </span>
            </div>
          </div>
        </div>
        <!--end: thirdPartyTypes -->
      </div>
    </div>
    <!--begin: Thirdparties flow -->
  </div>
</template>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>

<script>
import { mapGetters } from "vuex";

import statics from "@/core/statics/statics.js";
import helpers from "@/core/statics/helpers.js";

import KTUtil from "@/assets/js/components/util";
import Multiselect from "vue-multiselect";

export default {
  name: "wizard-FlowThirdParties",
  components: {
    Multiselect,
  },
  props: { syncedOperation: Object },
  data() {
    return {
      // Preferences
      showHelpDescriptions: statics.showHelpDescriptions,
      showBigIcons: statics.showBigIcons,
      showDataDebug: statics.showDataDebug,

      // Statics
      flowTypes: statics.flowTypes,
      thirdPartyTypes: statics.thirdPartyTypes,

      // Widget scroll to position
      scrollPosition: 0,
      lastScrollTop: window.pageYOffset || document.documentElement.scrollTop,

      // Partners Options list for multiselects
      searchedPartners: null,

      // Local form
      // flow_Form: {
      //   OperationType: undefined,
      //   MyRole: undefined,
      //   Partners: {} // Object because you need x Multislect single object
      // }
      // API Data
      // Partners: []
    };
  },
  mounted() {
    window.addEventListener("scroll", this.handleScroll);

    // Set default operation types > used when only one type available > to set default
    this.setDefaultOperationType(this.computedFlowTypes);

    // If there is a syncedOperation synced > then update the local flow_Form
    //this.flow_Form = this.syncedOperation;
    // const _syncedPartners = this.syncedOperation.Partners.reduce((obj, cur, i) => {
    //   return { ...obj, [i]: cur };
    // }, {});
    // this.selectedPartners = _syncedPartners;
  },
  created() {
    // // First Partners list mount >> KO
    // this.searchedPartners = this.Partners.filter((p) => !this.syncedOperation.Partners.map((p) => p.PartnerID).includes(p.PartnerID));
  },
  mixins: [helpers],
  methods: {
    emitPartner(data, id, thirdPartyType) {
      console.log("emitPartner::", data, id, thirdPartyType);

      let role = { PartnerRole: thirdPartyType };
      let merged = { ...role, ...data };
      console.log("emitPartner::merged", merged);

      this.$emit("update-partners", {
        [thirdPartyType]: merged,
      });
    },
    removePartner(data, id, thirdPartyType) {
      console.log("removePartner::", data, id, thirdPartyType);

      this.$emit("update-partners", {
        [thirdPartyType]: null,
      });
    },
    removeAllPartners() {
      console.log("removeAllPartner");
      this.$emit("remove-partners");
    },
    setMyRolePartner(id) {
      console.log("setMyRolePartner::", id); // typeof this.selectedPartners, typeof this.selectedPartners[id]
      // Remove last MyRole selected
      if (this.syncedOperation.lastMyRole !== undefined)
        this.removePartner(this.syncedOperation.Partners[this.syncedOperation.lastMyRole], null, this.syncedOperation.lastMyRole);

      // NO REACTIVE
      // this.syncedOperation.lastMyRole = id;
      //REACTIVE
      this.$set(this.syncedOperation, "lastMyRole", id);

      // Empty & fill syncedOperation Partner MyRole selected
      // NO REACTIVE
      // this.syncedOperation.Partners = [];
      //REACTIVE
      this.$set(this.syncedOperation, "Partners", []);
      this.syncedOperation.Partners.push({
        // Fill with a real PID
        PartnerRole: id,
        ...this.getUserPartner,
      });

      // Emit Partner MyRole selected
      //this.emitPartner(this.selectedPartners[id], null, id);

      // Scroll to anchor
      this.jumpToAnchor("anchorThirdParties");

      // Empty searchedPartners
      this.searchedPartners = null;
    },
    setDefaultOperationType(OperationTypes = this.computedFlowTypes) {
      // Conditions
      setTimeout(() => {
        console.log("setDefaultOperationType:: Types", OperationTypes, OperationTypes.length, this.syncedOperation.OperationType, this.syncedOperation.action);
        if (
          OperationTypes.length < 2 &&
          OperationTypes.length !== 0 &&
          this.syncedOperation.OperationType === null &&
          this.syncedOperation.action === "create"
        ) {
          console.log("setDefaultOperationType:: Set a single type ");

          // NO REACTIVE
          //this.syncedOperation.OperationType = undefined;
          // REACTIVE
          this.$set(this.syncedOperation, "OperationType", undefined);

          // NO REACTIVE
          // this.syncedOperation.OperationType = OperationTypes[0].id;
          // REACTIVE
          this.$set(this.syncedOperation, "OperationType", OperationTypes[0].id);

          // Emit update MyRole ( needed for default setting > Used to update POVs )
          this.$emit("update", {
            OperationType: this.syncedOperation.OperationType,
          });

          // Set Third party type
          this.setThirdPartyType(this.syncedOperation.OperationType, null);
        } else console.log("setDefaultOperationType:: Not a single type OR not a create action");
      }, 500);
    },
    setDefaultThirdPartyType(OperationType) {
      // NO REACTIVE
      // this.syncedOperation.MyRole = undefined;
      // REACTIVE
      this.$set(this.syncedOperation, "MyRole", undefined);

      console.log("setDefaultThirdPartyType::", this.getUserMainRole, OperationType, OperationType.includes(["C", "T"]));

      // Conditions
      if (this.getUserMainRole === "CH" && OperationType === "C") this.$set(this.syncedOperation, "MyRole", "MC"); // If CH & C | T > MC | if CH & D > MT
      if (this.getUserMainRole === "CH" && OperationType === "D") this.$set(this.syncedOperation, "MyRole", "MD");
      if (this.getUserMainRole === "CH" && OperationType === "T") this.$set(this.syncedOperation, "MyRole", "MC");

      if (this.getUserMainRole === "TR" && OperationType === "C") this.$set(this.syncedOperation, "MyRole", "MT"); // If TR & C | ?? > MT | if TR & T > MC
      if (this.getUserMainRole === "TR" && OperationType === "D") this.$set(this.syncedOperation, "MyRole", "MT");
      if (this.getUserMainRole === "TR" && OperationType === "T") this.$set(this.syncedOperation, "MyRole", "MC");

      // Emit update MyRole ( needed for default setting > Used to update POVs )
      this.$emit("update", {
        MyRole: this.syncedOperation.MyRole,
      });

      this.setMyRolePartner(this.syncedOperation.MyRole);
    },
    setThirdPartyType(id, e) {
      console.log(
        "setThirdPartyType::",
        id,
        e,
        this.flowTypes,
        this.syncedOperation.OperationType,
        this.flowTypes.filter((f) => f.id == this.syncedOperation.OperationType)[0].slug
      );
      if (e !== null) e.preventDefault();

      // New beginning !
      this.removeAllPartners();

      // Unset thirdPartyType
      this.setDefaultThirdPartyType(id);

      // Set flowType
      // NO REACTIVE
      // this.syncedOperation.OperationType = id;
      // REACTIVE
      this.$set(this.syncedOperation, "OperationType", id);

      // Emit update
      this.$emit("update", {
        OperationType: this.syncedOperation.OperationType,
        OperationTypeSlug: this.flowTypes.filter((f) => f.id == this.syncedOperation.OperationType)[0].slug,
      });

      // Scroll to anchor
      this.jumpToAnchor("anchorThirdPartyType");
    },
    jumpToAnchor(anchor) {
      // setTimeout(() => {
      //   KTUtil.scrollTo(KTUtil.getById(anchor), -90, 500);
      // }, 50);
    },
    haveAnyThirdParty() {
      console.log("haveAnyThirdParty::", Object.keys(this.syncedOperation.Partners).length);
      //return Object.keys(this.syncedOperation.Partners).every(k => !this.syncedOperation.Partners[k]) ? false : true; // null OR >0
      return Object.keys(this.syncedOperation.Partners).length > 1 ? true : false; // > 1
    },
    // isDisabled(slug) {
    //   // Set disabled or not
    //   if (this.syncedOperation.OperationType != undefined)
    //     return this.flowTypes[
    //       this.syncedOperation.OperationType
    //     ].allowedThirdPartyType.find(value => value == slug)
    //       ? false
    //       : true;
    //   else return true;
    // }
    isDisabled(id, key) {
      console.log("isDisabled::", id, key, this.syncedOperation.action, this.syncedOperation.OperationType, typeof this.syncedOperation.OperationType);
      // if (this.syncedOperation.action === "edit") return true;
      if (typeof id === "undefined" || typeof key === "undefined") return true;
      // Set disabled or not
      if (key === "thirdPartyType") {
        if (typeof this.syncedOperation.OperationType !== "undefined" && this.syncedOperation.OperationType !== null) {
          // console.log(this.flowTypes.filter(f => f.id == this.syncedOperation.OperationType)[0].allowedThirdPartyType);
          // console.log(this.flowTypes.filter(f => f.id == this.syncedOperation.OperationType)[0].allowedSubThirdPartyType);
          return this.flowTypes.filter((f) => f.id == this.syncedOperation.OperationType)[0].allowedThirdPartyType.find((t) => t == id) ||
            this.flowTypes.filter((f) => f.id == this.syncedOperation.OperationType)[0].allowedSubThirdPartyType.find((t) => t == id)
            ? false
            : true;
        } else return true;
        /*} else if (key === "thirdPartySelect") {
        if (typeof this.syncedOperation.OperationType !== "undefined" && this.syncedOperation.OperationType !== null) {
          // console.log(this.flowTypes.filter(f => f.id == this.syncedOperation.OperationType)[0].allowedThirdPartyType);
          // console.log(this.flowTypes.filter(f => f.id == this.syncedOperation.OperationType)[0].allowedSubThirdPartyType);
          return this.flowTypes
            .filter(f => f.id == this.syncedOperation.OperationType)[0]
            .allowedThirdPartyType.find(t => t == id && t != this.syncedOperation.MyRole) ||
            this.flowTypes
              .filter(f => f.id == this.syncedOperation.OperationType)[0]
              .allowedSubThirdPartyType.find(t => t == id && t != this.syncedOperation.MyRole)
            ? false
            : true;
        } else return true;*/
      } else if (key === "thirdPartySelect") {
        if (typeof this.syncedOperation.OperationType !== "undefined" && this.syncedOperation.OperationType !== null) {
          // console.log(this.thirdPartyTypes.filter(f => f.id == this.syncedOperation.OperationType)[0].allowedSubThirdPartySelect);
          return this.thirdPartyTypes.filter((f) => f.id == this.syncedOperation.MyRole)[0].allowedThirdPartySelect.find((t) => t == id) ||
            this.thirdPartyTypes.filter((f) => f.id == this.syncedOperation.MyRole)[0].allowedThirdPartySelect.find((t) => t == id)
            ? false
            : true;
        } else return true;
        //
      } else if (key === "FlowType") {
        // Example if more FlowTypes to come and handle
        if (id != "A") return false;
        else return true;
      } else {
        return true;
      }
    },
    isDisabledMultiselect(key) {
      //syncedOperation.MyRole === key.id
      if (key === this.syncedOperation.MyRole) {
        return true;
      } else {
        if (typeof this.syncedOperation.thirdpartyEdit !== "undefined" && key !== this.syncedOperation.thirdpartyEdit) return true;
        else return false;
      }
    },
    // Fix Multiselect search without Diacritics ( accents )
    /* inspired by https://github.com/shentao/vue-multiselect/issues/281#issuecomment-597718858 */
    /* inside map, this refer to Window and not App */
    /* https://stackoverflow.com/questions/46371302/map-unable-to-access-objects-this-function */
    ignoreAccents(search, partners) {
      const needle = _.lowerCase(_.deburr(search));

      // console.log(
      //   "ignoreAccents::",
      //   search,
      //   partners,
      //   id,
      //   search.normalize("NFD").replace(/[\u0300-\u036f]/g, ""),
      //   needle,
      //   this.Partners.filter((p) => !this.syncedOperation.Partners.map((p) => p.PartnerID).includes(p.PartnerID)),
      //   this.Partners.filter(
      //     (p) =>
      //       !this.syncedOperation.Partners.map((p) => p.PartnerID).includes(p.PartnerID) &&
      //       (_.lowerCase(_.deburr(p.Name)).includes(needle) ||
      //         _.lowerCase(_.deburr(p.City)).includes(needle) ||
      //         _.lowerCase(_.deburr(p.ZipCode)).includes(needle) ||
      //         (typeof p.PartnerRef != "undefined" ? _.lowerCase(_.deburr(p.PartnerRef)).includes(needle) : ""))
      //   )
      // );

      this.searchedPartners = search
        ? partners.filter(
            (p) =>
              _.lowerCase(_.deburr(p.Name)).includes(needle) ||
              _.lowerCase(_.deburr(p.City)).includes(needle) ||
              _.lowerCase(_.deburr(p.ZipCode)).includes(needle) ||
              _.lowerCase(_.deburr(p.Reference)).includes(needle) ||
              (typeof p.PartnerRef != "undefined" ? _.lowerCase(_.deburr(p.PartnerRef)).includes(needle) : "")
          )
        : null;
    },
    // List Partners for multislect options
    // updatePartners() {
    //   // Update Partners list when some selected ( remove selected for other select )
    //   // this.searchedPartners = this.Partners.filter((p) => !this.syncedOperation.Partners.map((p) => p.PartnerID).includes(p.PartnerID));
    //   // console.log("updatePartners::", this.searchedPartners);
    //   // Not used anymore
    // },

    // filteredPartners for multiselect in TP flow // Added AD
    filteredPartners: function (slug = "") {
      // console.log("filteredPartners::", this.syncedOperation.MyRole);
      // Carrier
      if (
        this.syncedOperation.MyRole !== "MT" &&
        (slug === "shipper" || slug === "receiver" || slug === "shipper-subcontractor" || slug === "receiver-subcontractor")
      ) {
        return this.Partners.filter(
          (p) =>
            !this.syncedOperation.Partners.map((p) => p.PartnerID).includes(p.PartnerID) &&
            p.Attributes.includes("isActive") &&
            (p.MainRole === "CH" || p.MainRole === "DE" || p.MainRole === "")
        );
      } else if (
        this.syncedOperation.MyRole === "MT" &&
        (slug === "shipper" || slug === "receiver" || slug === "shipper-subcontractor" || slug === "receiver-subcontractor")
      ) {
        return this.Partners.filter(
          (p) =>
            !this.syncedOperation.Partners.map((p) => p.PartnerID).includes(p.PartnerID) &&
            p.Attributes.includes("isActive") &&
            (p.MainRole === "CH" || p.MainRole === "DE" || p.MainRole === "AD" || p.MainRole === "")
        );
      } else if (slug === "carrier" || slug === "carrier-subcontractor") {
        return this.Partners.filter(
          (p) =>
            !this.syncedOperation.Partners.map((p) => p.PartnerID).includes(p.PartnerID) &&
            p.Attributes.includes("isActive") &&
            (p.MainRole === "TR" || p.MainRole === "")
        );
      } else if (slug === "notified") {
        return this.Partners.filter((p) => !this.syncedOperation.Partners.map((p) => p.PartnerID).includes(p.PartnerID));
      } else {
        return this.Partners.filter((p) => !this.syncedOperation.Partners.map((p) => p.PartnerID).includes(p.PartnerID) && p.Attributes.includes("isActive"));
      }
    },
  },
  computed: {
    ...mapGetters(["getDevice", "getPartnersFor", "getUserClientID", "getUserCapability", "getUserPartner", "getUserMainRole"]),
    // Get Partners data
    Partners: function () {
      return this.getPartnersFor("OPS");
    },
    // // DEV
    // computedPartners: {
    //   get() {
    //     return this.statusProxy === null ? true : this.statusProxy;
    //   },
    //   set(val) {
    //     this.statusProxy = val;
    //   }
    // },
    // Computed datas
    computedFlowTypes: function () {
      let flowTypes = [];
      let canLoadFlowTypes = ["C", "D"];
      let canTransferFlowTypes = ["T"];
      let canCharterFlowTypes = ["A"];
      let userFlowTypes = [];

      userFlowTypes = flowTypes;
      if (this.getUserCapability("CanLoad") === true) userFlowTypes = userFlowTypes.concat(canLoadFlowTypes);
      if (this.getUserCapability("CanTransfer") === true) userFlowTypes = userFlowTypes.concat(canTransferFlowTypes);
      if (this.getUserCapability("CanCharter") === true) userFlowTypes = userFlowTypes.concat(canCharterFlowTypes);

      // Remove charter of the flowType list : what is the flow ?
      if (typeof this.flowTypes !== "undefined")
        return this.flowTypes.filter((i) => {
          return userFlowTypes.find((f) => f === i.id);
        });
      else return [];
    },
    computedThirdPartyTypes: function () {
      // Only list allowed of the thirdPartyType list : who i am ?
      // if (typeof this.flowTypes !== "undefined" && typeof this.thirdPartyTypes !== "undefined" && this.syncedOperation.OperationType !== null)
      //   return this.thirdPartyTypes.filter(i => {
      //     return this.flowTypes.filter(f => f.id === this.syncedOperation.OperationType)[0].allowedThirdPartyType.find(t => t === i.id);
      //   });
      // else return [];

      let thirdPartyTypes = ["MC", "MT", "MD"];
      let CanSubcontractThirdPartyTypes = []; // Removed #562 "SC", "ST", "SD"
      let userThirdPartyTypes = [];
      userThirdPartyTypes = thirdPartyTypes;
      if (this.getUserCapability("CanSubcontract") === true) userThirdPartyTypes = userThirdPartyTypes.concat(CanSubcontractThirdPartyTypes);

      // Remove notified of the thirdPartyType list : who i am ?
      // removed : && this.syncedOperation.OperationType !== null to show disabled items
      if (typeof this.thirdPartyTypes !== "undefined")
        return this.thirdPartyTypes.filter((i) => {
          return userThirdPartyTypes.find((t) => t === i.id);
        });
      else return [];
    },
    computedThirdPartiesTypes() {
      // Remove non-allowed of the thirdPartyType list : who are the thirdparties ?

      /*let thirdPartyTypes = ["MC", "MT", "MD"];
      let CanSubcontractThirdPartyTypes = ["SC", "ST", "SD"];
      let userThirdPartyTypes = [];
      userThirdPartyTypes = thirdPartyTypes;
      if (this.getUserCapability("CanSubcontract") === true) userThirdPartyTypes = userThirdPartyTypes.concat(CanSubcontractThirdPartyTypes);

      // Remove notified of the thirdPartyType list : who i am ?
      if (typeof this.thirdPartyTypes !== "undefined" && this.syncedOperation.OperationType !== null)
        return this.thirdPartyTypes.filter(i => {
          return userThirdPartyTypes.find(t => t === i.id);
        });
      else return [];
      */

      /*if (typeof this.flowTypes !== "undefined" && typeof this.thirdPartyTypes !== "undefined" && this.syncedOperation.OperationType !== null)
        return this.thirdPartyTypes.filter(i => {
          return (
            this.flowTypes.filter((f) => f.id === this.syncedOperation.OperationType)[0].allowedThirdPartyType.find((t) => t === i.id) ||
            this.flowTypes.filter((f) => f.id === this.syncedOperation.OperationType)[0].allowedSubThirdPartyType.find((t) => t === i.id)
          );
        });
      else return [];*/

      let thirdPartyTypes = ["MC", "MT", "MD", "NN"];
      let CanSubcontractThirdPartyTypes = ["SC", "ST", "SD"];
      let isMobileDeviceThirdPartyTypes = ["MC", "MT", "MD"];
      let userThirdPartyTypes = [];
      userThirdPartyTypes = thirdPartyTypes;
      if (this.getUserCapability("CanSubcontract") === true) userThirdPartyTypes = userThirdPartyTypes.concat(CanSubcontractThirdPartyTypes);
      if (this.isMobileDevice) userThirdPartyTypes = isMobileDeviceThirdPartyTypes;

      // Remove notified of the thirdPartyType list : who i am ?
      if (typeof this.thirdPartyTypes !== "undefined" && this.syncedOperation.OperationType !== null)
        return this.thirdPartyTypes.filter((i) => {
          return userThirdPartyTypes.find((t) => t === i.id);
        });
      else return [];
    },

    // Device
    isMobileDevice() {
      return this.getDevice === "mobile" ? true : false;
    },
  },
};
</script>
